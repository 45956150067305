<template>
  <v-container>
    <h1 v-html="$t('search_invoices_read_title')"></h1>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-invoice ref="search-invoice" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <searchContactAttributeAndRelation
          name="searchcardAttributeAndRelation"
          store-name="SearchContactAttributesRelationsStore"
          :show-picker="true"
          ref="search-contact-attributes"
          @search="search"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="search"><span v-html="$t('invoices_read_search')" /></v-btn>
    <v-btn color="secondary" class="margin-left" @click="reset"><span v-html="$t('invoices_read_reset')" /></v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="invoices_read_bulk_changes" color="secondary" v-bind="props" :loading="loadingActions">
          <span v-html="$t('invoices_read_bulk_changes')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item id="invoices_read_bulk_change_print">
          <v-list-item-title v-html="$t('invoices_read_bulk_change_print')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_bulk_change_release">
          <v-list-item-title v-html="$t('invoices_read_bulk_change_release')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_bulk_change_close">
          <v-list-item-title v-html="$t('invoices_read_bulk_change_close')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_bulk_change_delete">
          <v-list-item-title v-html="$t('invoices_read_bulk_change_delete')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="invoices_read_downloads" color="secondary" v-bind="props" :loading="loadingDownload">
          <span v-html="$t('download')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item id="invoices_read_download">
          <v-list-item-title v-html="$t('invoices_read_download')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_download_pos">
          <v-list-item-title v-html="$t('invoices_read_download_pos')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_download_detailed">
          <v-list-item-title v-html="$t('invoices_read_download_detailed')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_download_debtor">
          <v-list-item-title v-html="$t('invoices_read_download_debtor')"></v-list-item-title>
        </v-list-item>
        <v-list-item id="invoices_read_download_bookings">
          <v-list-item-title v-html="$t('invoices_read_download_bookings')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-data-table-server
      :headers="fields"
      :items="invoiceItems"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      density="compact"
    >
      <!--      todo WER-105 -->
      <template v-slot:[`item.edit`]="{ item }">
        <div class="link">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          <!--      todo add links-->
          <!--            <router-link-->
          <!--              :to="{-->
          <!--                name: 'contacts_edit',-->
          <!--                params: { id: invoice.relatedInvoiceId, tab: 'overview' }-->
          <!--              }"-->
          <!--              class="tableaction link"-->
          <!--              >{{ item.contactId }}-->
          <!--            </router-link>-->
        </div>
      </template>

      <template v-slot:[`item.relatedInvoices`]="{ item }">
        <template v-for="invoice in item.relatedInvoices">
          <template v-if="invoice.relatedInvoiceId && $privileges.has({ path: '/invoices', permission: 'read' })">
            <!--      todo add links-->
            <!--            <router-link-->
            <!--              :to="{-->
            <!--                name: 'contacts_edit',-->
            <!--                params: { id: invoice.relatedInvoiceId, tab: 'overview' }-->
            <!--              }"-->
            <!--              class="tableaction link"-->
            <!--              >{{ item.contactId }}-->
            <!--            </router-link>-->
            {{ invoice.relatedInvoiceIdent }},
          </template>
          <template v-else>
            {{ invoice.relatedInvoiceIdent }}
          </template>
        </template>
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        <span>{{ item.dueDate ? format(item.dueDate, 'dd.MM.yyyy') : '' }} </span>
      </template>
      <template v-slot:[`item.invoiceStatus`]="{ item }">
        {{ item.invoiceStatus ? item.invoiceStatusTranslated['name' + $getUpLang()] : '' }}
      </template>
      <template v-slot:[`item.invoiceType`]="{ item }">
        {{ item.invoiceType ? item.invoiceTypeTranslated['name' + $getUpLang()] : '' }}
      </template>
      <template v-slot:[`item.invoiceDebtorType`]="{ item }">
        {{ item.invoiceDebtorType ? item.invoiceDebtorTypeTranslated['name' + $getUpLang()] : '' }}
      </template>

      <template v-slot:[`item.contactId`]="{ item }">
        <template v-if="item.contactId && $privileges.has({ path: '/invoices', permission: 'read' })">
          <router-link
            :to="{
              name: 'contacts_edit',
              params: { id: item.contactId, tab: 'overview' }
            }"
            class="tableaction link"
            >{{ item.contactId }}
          </router-link>
          ({{ item.contactFullName }})
        </template>
        <template v-else> {{ item.contactId }} ({{ item.contactFullName }})</template>
      </template>

      <template v-slot:[`item.periodUntil`]="{ item }">
        <span>{{ item.periodFrom ? format(item.periodFrom, 'MM.yyyy') + ' - ' : '' }} {{ item.periodUntil ? format(item.periodUntil, 'MM.yyyy') : '' }}</span>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ $formatNumber(item['quantity'], 2) }}
      </template>

      <!--      todo WER-57   -->
      <template v-slot:[`item.preview`]="{ item }">
        <template v-if="item.id && $privileges.has({ path: '/invoices', permission: 'read' })"> PDF (TBD) </template>
        <template v-else> PDF (TBD)</template>
      </template>

      <!--      todo WER-81   -->
      <template v-slot:[`item.detail`]="{ item }">
        <template v-if="item.id && $privileges.has({ path: '/invoices', permission: 'read' })"> CSV (TBD) </template>
        <template v-else> CSV (TBD)</template>
      </template>

      <!--      todo Link zum PDF (via message_uid, API ComLog/Messages)-->
      <template v-slot:[`item.print`]="{ item }">
        <template v-if="item.messageUid && $privileges.has({ path: '/invoices', permission: 'read' })">
          <v-icon dbmblueprimary>mdi-printer</v-icon>
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script setup lang="ts">
import { ref, reactive, computed, onMounted, watch, getCurrentInstance } from 'vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import searchContact from '@/components/searchCards/searchContact.vue'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import searchContactAttributeAndRelation from '@/components/searchCards/searchContactAttributeAndRelation.vue'
import { Term } from '@/services/term'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import SearchInvoice from '@/components/searchCards/searchInvoice.vue'
import { useSearchInvoiceStore } from '@/store/SearchInvoiceStore'
import { format } from 'date-fns'
import { DTSHeaders } from '@/services/BackendService'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import { useSearchContactStore } from '@/store/SearchContactStore'

const invoiceItems = ref<InvoiceItem[]>([])
const totalElements = ref(0)
const loading = ref(false)
const loadingDownload = ref(false)
const loadingActions = ref(false)
const { proxy } = getCurrentInstance()

interface InvoiceItem {
  id: number
  dueDate: string
  invoiceRunId: number
  invoiceStatus: string
  invoiceType: string
  invoiceIdent: number
  invoiceDebtorType: string
  contactId: number
  contactFullName: string
  quantity: number
  periodFrom: string
  periodUntil: string
  messageUid: string
  invoiceStatusTranslated: {
    nameDe: string
    nameFr: string
    nameIt: string
  }
  invoiceTypeTranslated: {
    nameDe: string
    nameFr: string
    nameIt: string
  }
  invoiceDebtorTypeTranslated: {
    nameDe: string
    nameFr: string
    nameIt: string
  }
  relatedInvoices: [
    {
      relatedInvoiceId: string
      relatedInvoiceIdent: string
    }
  ]
}

const fields = reactive<DTSHeaders>([
  {
    key: 'edit',
    sortable: false,
    width: '2%'
  },
  // RE-Nr.
  {
    title: proxy.$t('invoices_read_invoice_no'),
    key: 'invoiceIdent',
    sortable: true
  },
  // RE-Nr. verknüpft
  {
    title: proxy.$t('invoices_read_invoice_no_connected'),
    key: 'relatedInvoices',
    sortable: true
  },
  // RE-Datum
  {
    title: proxy.$t('invoices_read_invoice_date'),
    key: 'approval',
    sortable: true
  },
  // RE-Lauf
  {
    title: proxy.$t('invoices_read_invoice_run'),
    key: 'invoiceRunId',
    sortable: true,
    align: 'end'
  },
  // RE-Status
  {
    title: proxy.$t('invoices_read_invoice_status'),
    key: 'invoiceStatus',
    sortable: true
  },
  // Faktura-Typ
  {
    title: proxy.$t('invoices_read_invoice_type'),
    key: 'invoiceType',
    sortable: true
  },
  // Deb-Gruppe
  {
    title: proxy.$t('invoices_read_debtor_group'),
    key: 'invoiceDebtorType',
    sortable: true
  },
  // Debitor
  {
    title: proxy.$t('invoices_read_debtor'),
    key: 'contactId',
    sortable: true
  },
  // Leistungszeitraum
  {
    title: proxy.$t('invoices_read_debtor_period'),
    key: 'periodUntil',
    sortable: true
  },
  // Betrag
  {
    title: proxy.$t('invoices_read_amount'),
    key: 'quantity',
    sortable: true,
    align: 'end'
  },
  // RE-Ansicht
  {
    title: proxy.$t('invoices_read_invoice_preview'),
    key: 'preview',
    sortable: true
  },
  // Detail
  {
    title: proxy.$t('invoices_read_detail'),
    key: 'detail',
    sortable: true
  },
  // RE (original)
  {
    title: proxy.$t('invoices_read_invoice_original'),
    key: 'print',
    sortable: true
  }
])

const searchInvoiceStore = useSearchInvoiceStore()

const queryParams = computed(() => ({
  invoiceStatus: searchInvoiceStore.invoiceStatus,
  periodFrom: searchInvoiceStore.periodFrom,
  periodUntil: searchInvoiceStore.periodUntil,
  invoiceDebtorType: searchInvoiceStore.invoiceDebtorType,
  ident: searchInvoiceStore.ident,
  invoiceRunId: searchInvoiceStore.invoiceRunId,
  invoiceTypeIds: searchInvoiceStore.invoiceTypeIds,
  smpEncashmentEntityId: searchInvoiceStore.smpEncashmentEntityId,
  debtorNo: searchInvoiceStore.debtorNo,
  approved: searchInvoiceStore.approved,
  amountMin: searchInvoiceStore.amountMin,
  amountMax: searchInvoiceStore.amountMax,
  sendingChannel: searchInvoiceStore.sendingChannel
}))

const term = computed(() => [
  ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
  ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
  ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
  ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items)
])

const query = computed(() => ({
  term: Term.stringify(term.value),
  ...queryParams.value,
  ...proxy.getJavaPageOptions({ sort: ['invoiceStatus, asc', 'invoiceRunId, desc', 'periodUntil, desc'] })
}))

const search = async () => {
  proxy.$router.push({ path: proxy.$route.path, query: query.value }).catch(() => {
    load()
  })
}

const load = async () => {
  loading.value = true
  try {
    invoiceItems.value = []
    const response = await proxy.axios.get(apiURL + '/invoices/find', { params: query.value })
    const data: InvoiceItem[] = response.data.content
    invoiceItems.value = Array.isArray(data) ? data : []
    totalElements.value = proxy.checkPage(response.data.totalElements)
  } catch (e) {
    showError(e)
  } finally {
    loading.value = false
  }
}

const reset = () => {
  searchInvoiceStore.$reset()
  proxy.$refs['search-contact'].reset()
  proxy.$refs['search-farm'].reset()
  proxy.$refs['search-contact-attributes'].reset()
  proxy.$refs['search-legal-entity'].reset()
  proxy.$refs['search-dairy'].reset()
}

watch(
  () => proxy.$route.query,
  () => {
    load()
  },
  { deep: true }
)

onMounted(() => {
  if (proxy.$route.query.size) load()
})
</script>
